/* jshint browser: true */
/* globals angular */

const facetDictionary = {
	q: 'Query',
	category_s: 'Category',
	product_categories_sm: 'OnBase Product Category',
	__semantics: 'Group',
	updateddaterange: 'Date Range',
	resource_type_s: 'Resource Types',
	item_language_s: 'Language',
	industries_sm: 'Industry',
	key_strategies_sm: 'Key Strategies',
	regions_sm: 'Region',
	departments_sm: 'Department',
	integrations_sm: 'Integration',
	events_sm: 'Events',
	channel_s: 'Channel',
	product_versions_sm: 'Product Releases',
	business_categories_sm: 'Product Group',
	idea_status_s: 'Idea Status',
	idea_type_s: 'Idea Type',
	idea_owner_display_name_s: 'Idea Owner',
	industry_submitted_by_s: 'Industry Submitted By',
	idea_product_line_s: 'Idea Product Line',
	daterange: "Created Date",
	ideavotes: "Idea Votes",
	domain_t: 'Search For',
	community_author_t: 'Author',
	forum_reply_type_s: 'Forum Response Type',
	forum_thread_detailed_type_s: 'Forum Thread Type',
	modifieddaterange: 'Last Modified',
	metadata_group_type_s: 'Group Type',
	user_group_type_s: 'User Group Type',
	group_type_s: 'Group Type',
	group_interest_s: 'Group Interest',
	group_industry_s: 'Group Industry',
	lab_or_download_sm: 'Content Type',
	content_services_categories_sm: 'Content Services Category',
	products_sm: 'Product Lines',
	platform_strength_categories_sm: 'Platform Strengths',
	readaccess_sm: 'Role Access',
	license_tier_s: 'License Tier (OnBase Only)',
}

var SearchController = function ($http, $location) {
	var vm = this;
	vm.results = {};
	vm.query = $location.search().q || '';
	vm.loading = true;
	vm.shouldShowPills = false;

	let qs = (obj, prefix) => {
		let str = [];
		for (let p in obj) {
			if (obj[p]) {
				let k = prefix ? prefix + "[" + p + "]" : p,
					v = obj[k];
				if (angular.isObject(v)) {
					v = Object.keys(v).map(k => v[k]).filter(function (t) {
						return t != '';
					}).join(',');
				}
				str.push(k + "=" + encodeURIComponent(v));
			}
		}
		return str.join("&");
	};

	var objFromQS = function (s) {
		if (s) {
			let obj = {};
			s.split(',').forEach(function (k) {
				obj[k] = k;
			});

			return obj;
		}
	};

	const filterOutEmpty = facet => {
		return facet.filter(c => c.Key !== '00000000000000000000000000000000');
	}

	vm.searchParameters = {
		q: $location.search().q,
		category_s: objFromQS($location.search().category_s),
		product_categories_sm: objFromQS($location.search().product_categories_sm),
		__semantics: objFromQS($location.search().__semantics),
		updateddaterange: objFromQS($location.search().updateddaterange),
		resource_type_s: objFromQS($location.search().resource_type_s),
		item_language_s: objFromQS($location.search().item_language_s),
		industries_sm: objFromQS($location.search().industries_sm),
		key_strategies_sm: objFromQS($location.search().key_strategies_sm),
		regions_sm: objFromQS($location.search().regions_sm),
		departments_sm: objFromQS($location.search().departments_sm),
		integrations_sm: objFromQS($location.search().integrations_sm),
		events_sm: objFromQS($location.search().events_sm),
		channel_s: $location.search().channel_s,
		product_versions_sm: objFromQS($location.search().product_versions_sm),
		business_categories_sm: objFromQS($location.search().business_categories_sm),
		website_s: objFromQS($location.search().website_s),
		idea_status_s: objFromQS($location.search().idea_status_s),
		idea_type_s: $location.search().idea_type_s,
		ideavotes: objFromQS($location.search().ideavotes),
		idea_owner_display_name_s: objFromQS($location.search().idea_owner_display_name_s),
		industry_submitted_by_s: objFromQS($location.search().industry_submitted_by_s),
		idea_product_line_s: objFromQS($location.search().idea_product_line_s),
		daterange: objFromQS($location.search().daterange),
		order_by: objFromQS($location.search().order_by),
		pageIndex: objFromQS($location.search().pageIndex),
		pageSize: objFromQS($location.search().pageSize),
		_template: objFromQS($location.search()._template),
		community_author_t: $location.search().community_author_t,
		forum_reply_type_s: $location.search().forum_reply_type_s,
		modifieddaterange: objFromQS($location.search().modifieddaterange),
		forum_thread_detailed_type_s: $location.search().forum_thread_detailed_type_s,
		metadata_group_type_s: objFromQS($location.search().metadata_group_type_s),
		domain_t: objFromQS($location.search().domain_t),
		content_type_s: objFromQS($location.search().content_type_s),
		extras: $location.search().extras,
		user_group_type_s: objFromQS($location.search().user_group_type_s),
		trysearch: true,
		content_services_categories_sm: objFromQS($location.search().content_services_categories_sm),
		products_sm: objFromQS($location.search().products_sm),
		platform_strength_categories_sm: objFromQS($location.search().platform_strength_categories_sm),
		lab_or_download_sm: objFromQS($location.search().lab_or_download_sm),
		readaccess_sm: objFromQS($location.search().readaccess_sm),
		license_tier_s: objFromQS($location.search().license_tier_s),
	};

	vm.hasSearched = function () {
		var keys = Object.keys($location.search());
		var hasValue = false;

		keys.forEach(function (k) {
			if ($location.search()[k] != '' && k != 'trysearch' && k != '_gl') hasValue = true;
		});

		return keys && hasValue;
	}

	const orderCalculator = [
		"Essential",
		"Standard",
		"Premier",
		"Horizontal Packages",
		"Vertical Packages",
	]

	async function orderLicenseTiers(tiers, lookup) {
		return tiers.sort((a, b) => {
			var firstname = lookup[a.Key];
			var secondname = lookup[b.Key];

			if (orderCalculator.indexOf(firstname) < orderCalculator.indexOf(secondname)) {
				return -1;
			} else if (orderCalculator.indexOf(firstname) > orderCalculator.indexOf(secondname)) {
				return 1;
			}
			return 0;
		});
	}

	vm.isZeroed = o => o === '00000000000000000000000000000000' || o.length === 0;

	vm.search = () => {
		vm.loading = true;
		$http.get('/api/search/v2/query?' + qs(vm.searchParameters))
			.then(function (response) {
				vm.results = response.data;

				Object.keys(response.data.Facets).forEach(async (c) => {
					if (c === "readaccess_sm") {
						vm.results.Facets[c] = response.data.Facets[c].filter(x => x.Key.indexOf('Reseller') > -1 || x.Key.indexOf('Partner') > -1 || x.Key.indexOf('Customer') > -1 || x.Key.indexOf('End User') > -1);
					}
					else if (c === "license_tier_s") {
						var tiers = await orderLicenseTiers(response.data.Facets[c], response.data.TagsLookup);
						vm.results.Facets[c] = filterOutEmpty(tiers);
					} else {
						vm.results.Facets[c] = filterOutEmpty(response.data.Facets[c]);
					}					
				});
			})
			.finally(function () {
				vm.loading = false;
				tagPillFactory();
				setTimeout(function () {
					determineOpenness();
					magic();
				}, 300);
			});
	}

	var magic = function () {
		var timeout = 0;
		var container = document.querySelector('.search-results');

		if (container) {
			Array.from(container.querySelectorAll('.search-result-item')).forEach(function (i) {
				timeout = timeout + 100;
				tada(i, timeout);
			});
		}
	}

	var tada = function (elem, timeout) {
		setTimeout(function () {
			elem.classList.add('appear');
		}, timeout);
	}

	vm.search();

	const oneOffs = {
		solution_popularity_tl: "Popular",
		"spotlight_b:true": "Spotlight"
	}

	function tagPillFactory() {
		vm.facetTags = {};
		for (var param in $location.search()) {
			if (param === 'q' || param === 'trysearch' || param === 'extras') continue;
			var value = $location.search()[param];

			if (value) {				
				var split = value.split(',');
				split.forEach(function (s, i) {
					if (!s) return;

					if (s.indexOf("shared") === 0) {
						vm.facetTags[param + i] = s.replace("shared", "");
					} else {
						vm.facetTags[param + i] = oneOffs[s] || s;
					}				
				});
			}
		}
	}

	const makeThisPencilDisappear = function () {
		var container = document.querySelector('.search-results');

		if (container) {
			Array.from(container.querySelectorAll('.search-result-item')).forEach(function (t) {
				t.style.opacity = 0;
			});
		}
	}

	vm.removeFacet = function (value, key) {
		vm.loading = true;

		makeThisPencilDisappear();
		for (let param in vm.searchParameters) {
			let searchParam = vm.searchParameters[param];
			if (angular.isObject(searchParam)) {
				for (let subparam in searchParam) {
					if (subparam === value || subparam === `shared${value}` || subparam === `shared\\${value}`) {
						searchParam[subparam] = undefined;
						searchParam = Object.keys(searchParam).filter(c => searchParam[c]);
					}
				}
			}
		}

		if (value === 'Spotlight') vm.searchParameters.extras = undefined;
		if (value === 'Popular') vm.searchParameters.order_by = undefined;

		vm.searchParameters[value] = undefined;
		vm.searchParameters.pageIndex = 0;

		$location.search(qs(vm.searchParameters));
		vm.search();
	};

	vm.processSearchTerm = function (e) {
		if (!e || e.keyCode === 13) {
			makeThisPencilDisappear();
			$location.search(qs(vm.searchParameters));
			vm.search();
		}
	}

	vm.hasValidFacets = (k, v) => facetDictionary[k] && v.length > 0;

	vm.applyFilter = function (name, value) {

		makeThisPencilDisappear();

		window.scrollTo(0, 0);

		vm.loading = true;
		if (name && value) {
			vm.searchParameters[name] = value;
		}

		vm.searchParameters.pageIndex = 0;

		$location.search(qs(vm.searchParameters));
		vm.search();
	};

	vm.openFacetPanel = function (e) {
		var parent = e.target.classList.contains('search-facet-header') ? e.target.parentElement : e.target.parentElement.parentElement;
		parent.querySelector('.collapsed').classList.toggle('opened');

		parent.querySelector('.fa-chevron-down').classList.toggle('rotate');
	}

	let init = true;

	const determineOpenness = function () {
		vm.shouldShowPills = false;
		var availableFacets = Array.from(document.querySelectorAll('.search-facet'));

		availableFacets.forEach(function (facet) {
			var checked = Array.from(facet.querySelectorAll('input[type="checkbox"]')).filter(function (c) {
				return c.checked;
			});

			if (checked.length > 0) vm.shouldShowPills = true;
			if (checked.length > 0 && !facet.querySelector('ul').classList.contains('opened')) {
				facet.querySelector('.search-facet-header').click();
			}

			if (facet.querySelector('div') && facet.querySelector('div').innerText === 'Industry' && init) {
				facet.querySelector('.search-facet-header').click();
				init = false;
			}
		});
	}

	const searchForm = document.querySelector('.search-form');

	if (searchForm) searchForm.parentNode.removeChild(searchForm);

	vm.clearFilters = () => {
		vm.loading = true;
		makeThisPencilDisappear();
		vm.searchParameters = {
			q: '',
			trysearch: true
		}

		$location.search(qs(vm.searchParameters));
		vm.search();
		determineOpenness();
	}
}

var app = angular.module('searchApp', ['ngSanitize'])
	.config(['$locationProvider', function ($locationProvider) {
		$locationProvider.html5Mode(true);
	}])
	.controller('SearchController', ['$http', '$location', SearchController])
	.filter('rosetta', function () {
		return function (key) {
			return facetDictionary[key];
		}
	})
	.filter('lookup', function () {
		return function (k, lookup) {
			if (k.indexOf("shared") === 0) return k.replace("shared\\", "");
			return lookup[k] || k;
		}
	})
	.filter('truncate', function () {
		return function (t) {
			if (!t) return '';
			if (t.length > 100) {
				return t.substr(0, 100) + '...'
			}

			return t;
		}
	});
